import axios from 'axios';
import FormData from 'form-data';
import Bugsnag from '@bugsnag/js';
import { googleLogout } from '@react-oauth/google';
import { NetworkActivityTracker } from '../../utils/network/NetworkActivityTracker';

import * as Sentry from '@sentry/react';

const mnmxIoCommon = require('@conserv/mnmx-io-common');
var LOG = mnmxIoCommon.getConsoleLogger('Api.js');
const d4l = mnmxIoCommon.logUtil.d4l;

const loginRedirect = () => {
	if (
		!window.location.pathname.includes('/login') &&
		!window.location.pathname.includes('/public/')
	) {
		const queryparams = encodeURIComponent(window.location.search);
		const pathname = encodeURIComponent(window.location.pathname);
		window.location.href = `/login?redirected=true&path=${pathname}&query=${queryparams}`;
	}
};

axios.defaults.baseURL = process.env.REACT_APP_CONSERV_API_ENDPOINT;
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		// only interested in 500 errors at the moment.
		if (error.response.status >= 500 && error.response.status < 600) {
			Bugsnag.notify(error, event => {
				event.context = 'api';
				event.addMetadata('apiDetails', {
					url: error?.request?.responseURL,
					text: error?.request?.responseText
				});
			});
		}

		if (error.response.status === 401) {
			// if this user has an active Google Auth session, kill it
			googleLogout();
			loginRedirect();
		}
		return Promise.reject(error);
	}
);

const Api = {
	login(state) {
		try {
			return axios('/api/auth/login', {
				method: 'post',
				data: {
					email: state.email,
					pass: state.pass
				}
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	loginoauth(user, token) {
		try {
			return axios('/api/auth/oauthlogin', {
				method: 'post',
				data: {
					user,
					id_token: token
				}
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	validateToken() {
		try {
			return axios('/api/auth/validate', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	verifyEmail(token) {
		try {
			return axios('/api/account/email/verify', {
				method: 'post',
				data: {
					token
				}
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	resendVerification(email) {
		try {
			return axios('/api/account/email/verify/resend', {
				method: 'post',
				data: {
					email
				}
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getCustomer() {
		try {
			return axios('/api/customer/', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getCustomerModules() {
		try {
			return axios('/api/customer/module', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getCustomerUsers() {
		try {
			return axios('/api/customer/users', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	deactivateUser(id, customerId) {
		try {
			return axios(`/api/user/${id}`, {
				method: 'delete',
				data: {
					customerId
				}
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updateCustomer(customer) {
		try {
			return axios.patch('/api/v2/customers', {
				data: customer.customer
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getJoinableCustomers() {
		try {
			return axios('/api/customer/join/list', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	joinCustomer(linkuuid) {
		try {
			return axios(`/api/customer/join/${linkuuid}`, {
				method: 'post'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getFloorsAndHvacZonesByLocation(locationuuid) {
		try {
			return axios(`/api/properties/list_floor_and_hvac/${locationuuid}`, {
				method: 'get'
			});
		} catch (error) {
			Sentry.captureException(error);
			return error;
		}
	},

	getSpacesUsingFloorHvacZoneByUuid(propertyuuid) {
		try {
			return axios(`/api/properties/list_spaces/${propertyuuid}`, {
				method: 'get'
			});
		} catch (error) {
			Sentry.captureException(error);
			return error;
		}
	},

	deleteFacilityFloorHvacZone(facilityid, propertyuuid) {
		try {
			return axios(`/api/facility/floorHvac/${facilityid}/${propertyuuid}`, {
				method: 'delete'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getFacility(facilityId) {
		try {
			return axios(`/api/facility/${facilityId}`, {
				method: 'get'
			});
		} catch (error) {
			Sentry.captureException(error);
			return error;
		}
	},

	getFacilities() {
		try {
			return axios('/api/customer/facilities', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	deleteFacility(facilityid) {
		try {
			return axios(`/api/facility/${facilityid}`, {
				method: 'delete'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getFacilityZones(facilityId) {
		try {
			return axios(`/api/facility/${facilityId}/zones`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	makeFacilityPrimary(facilityId) {
		try {
			return axios(`/api/facility/${facilityId}/makeprimary`, {
				method: 'post'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getZoneSensors(zoneId) {
		try {
			return axios(`/api/zone/${zoneId}/sensors`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updateZone(zone) {
		const zoneData = {
			...zone.data,
			id: zone?.id || zone.data.zoneId,
			facilityId: zone?.facilityId || zone.data.facilityId
		};

		const formData = new FormData();
		formData.append('data', JSON.stringify(zoneData));
		zone.files?.forEach(file => formData.append('files', file));

		const zoneId = zone?.id || zone.data.zoneId;

		try {
			return axios(`/api/zone/${zoneId}`, {
				method: 'put',
				data: formData,
				headers: { 'Content-Type': 'multipart/form-data' }
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	createFacilityZone(zone) {
		const formData = new FormData();
		formData.append('data', JSON.stringify(zone.data));
		zone.files?.forEach(file => formData.append('files', file));

		try {
			return axios(`/api/facility/${zone.data.facilityId}/zone`, {
				data: formData,
				headers: { 'Content-Type': 'multipart/form-data' },
				method: 'post'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getAllZones() {
		try {
			return axios('/api/zone/all', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getFacilityGateways(facilityId) {
		try {
			return axios(`/api/facility/${facilityId}/gateways`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updateFacilityGateway(facilityId, gateway) {
		try {
			return axios(`/api/facility/${facilityId}/gateway`, {
				method: 'post',

				data: gateway
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getCustomerGateways() {
		try {
			return axios('/data/api/gateway/status/all', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	deleteZone(zoneId) {
		try {
			return axios(`/api/zone/${zoneId}`, {
				method: 'delete'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	deleteSensor(sensorId) {
		try {
			return axios(`/api/sensor/${sensorId}`, {
				method: 'delete'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	createSensor(sensor) {
		const formData = new FormData();
		formData.append('data', JSON.stringify(sensor.data));
		sensor.files.forEach(file => formData.append('files', file));

		try {
			return axios('/api/sensor/', {
				data: formData,
				headers: { 'Content-Type': 'multipart/form-data' },
				method: 'POST'
			});
		} catch (error) {
			Sentry.captureException(error);
			return error;
		}
	},

	updateSensor(sensor) {
		const formData = new FormData();
		formData.append('data', JSON.stringify(sensor.data));
		sensor.files?.forEach(file => formData.append('files', file));

		try {
			return axios(`/api/sensor/${sensor.data.uuid}`, {
				data: formData,
				headers: { 'Content-Type': 'multipart/form-data' },
				method: 'put'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updateSensorImage(uuid, image) {
		const formData = new FormData();
		formData.append('image', image[0]);
		try {
			return axios.post(`/api/sensor/${uuid}/image`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getSensorHistory(sensorUuid) {
		try {
			return axios(`/api/sensor/history/${sensorUuid}`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	moveSensor(moveDetails) {
		try {
			return axios('/api/sensor/move', {
				method: 'post',
				data: moveDetails
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	mergeSensor(mergeDetails) {
		try {
			return axios('/api/sensor/merge', {
				method: 'post',
				data: mergeDetails
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	unmergeSensor(sensorId) {
		try {
			return axios(`/api/sensor/unmerge/${sensorId}`, {
				method: 'post'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	restoreSensor(sensorId) {
		try {
			return axios(`/api/sensor/restore/${sensorId}`, {
				method: 'post'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updateGateway(gateway) {
		try {
			return axios(`/api/gateway/${gateway.uuid}`, {
				method: 'patch',
				data: {
					name: gateway.name,
					description: gateway.description
				}
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	deleteFacilityGateway(gatewayId) {
		try {
			return axios(`/api/gateway/${gatewayId}`, {
				method: 'delete'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getGatewayStatus(gatewayEUI) {
		try {
			return axios(`/api/v2/gateway/${gatewayEUI}/status`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getFacilitySensors(facilityId) {
		try {
			return axios(`/api/facility/${facilityId}/sensors`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getFacilityUsers(facilityId) {
		try {
			return axios(`/api/facility/${facilityId}/users`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updateFacility(facility) {
		const formData = new FormData();
		formData.append('data', JSON.stringify(facility.data));
		facility.files.forEach(file => formData.append('files', file));

		try {
			return axios(`/api/facility/${facility.data.id}`, {
				data: formData,
				headers: { 'Content-Type': 'multipart/form-data' },
				method: 'put'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	createFacility(facility) {
		const formData = new FormData();
		formData.append('data', JSON.stringify(facility.data));
		facility.files.forEach(file => formData.append('files', file));

		try {
			return axios('/api/facility/', {
				data: formData,
				headers: { 'Content-Type': 'multipart/form-data' },
				method: 'post'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	addUsersToFacility(facilityId, users) {
		try {
			return axios(`/api/facility/${facilityId}/user`, {
				method: 'put',
				data: users
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	removeUserFromFacility(facilityId, userId) {
		try {
			return axios(`/api/facility/${facilityId}/user/${userId}`, {
				method: 'delete'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getUser() {
		try {
			return axios('/api/user', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	createUser(user) {
		try {
			return axios('/api/user', {
				method: 'post',
				data: user
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updateUser(user) {
		try {
			return axios(`/api/user/${user.uuid}`, {
				method: 'put',
				data: user
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	checkUserExists(email) {
		try {
			return axios('/api/user/exists', {
				method: 'post',
				data: { email }
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getRoles() {
		try {
			return axios('/api/auth/roles', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getPreferences() {
		try {
			return axios('/api/user/my/preferences', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updatePreferences(prefs) {
		try {
			return axios('/api/user/my/preferences', {
				method: 'post',

				data: prefs
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getSensorTypes() {
		try {
			return axios('/api/sensor/types', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getGatewayTypes() {
		try {
			return axios('/api/gateway/types', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getSensor(sensorid) {
		try {
			return axios(`/api/sensor/${sensorid}`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getSensorDataV2(id, type, params) {
		try {
			return axios(`/data/api/v2/sensor/${id}/${type}`, {
				method: 'get',
				params
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getSensorDataV3(id, type, params) {
		try {
			return axios(`/data/api/v3/sensor/${id}/${type}`, {
				method: 'get',
				params
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getSensorAnalyticsData({ sensorId, params, signal }) {
		try {
			return axios(`/api/v2/readings/sensor/${sensorId}`, {
				method: 'get',
				params,
				signal
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getSingleSensorReading(deviceId, field) {
		try {
			return axios(`/data/api/v2/sensor/${deviceId}/${field}`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getLastSensorData(deviceId) {
		try {
			return axios(`/data/api/v2/sensor/${deviceId}/last?includefirst=true`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getSensorStats(params) {
		try {
			return axios('/data/api/v2/sensor/statsall', {
				method: 'get',
				params
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getSpaceStats() {
		try {
			return axios('/data/api/space/all', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getSensorLevelRangeCompliance(sensorId, reading, params) {
		try {
			return axios(`/data/api/level/evaluate/range/${sensorId}/${reading}`, {
				method: 'get',
				params
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getSensorLevelVariationCompliance(sensorId, reading, params) {
		try {
			return axios(
				`/data/api/level/evaluate/variation/${sensorId}/${reading}`,
				{
					method: 'get',
					params
				}
			);
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getSensorLightHoursCompliance(sensorId, params) {
		try {
			return axios(`/data/api/level/evaluate/hourslightson/${sensorId}`, {
				method: 'get',
				params
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getCumulativeLightHours(sensorId, params) {
		try {
			return axios(`/data/api/kpi/${sensorId}/luxhours`, {
				method: 'get',
				params
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getMoldRisk(sensorId, params) {
		try {
			return axios(`/data/api/kpi/${sensorId}/moldrisk`, {
				method: 'get',
				params
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getDamageRisk(sensorId, params) {
		try {
			return axios(`/data/api/kpi/${sensorId}/damagerate`, {
				method: 'get',
				params
			});
		} catch (err) {
			NetworkActivityTracker.pingWarn();
			// one level up
			// damageRiskProviderService.setAvailability(false)
			Sentry.captureException(err);
			return err;
		}
	},

	getAllLevelCompliance(sensorId, params) {
		try {
			return axios(`/data/api/level/evaluate/all/${sensorId}`, {
				method: 'get',
				params
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getBatchReportData(params) {
		try {
			return axios('/data/api/reports/batch', {
				method: 'get',
				params
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getFacilityHealth(facilityId) {
		try {
			return axios(`/data/api/facility/${facilityId}/rf`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getFacilityGraphs(facilityId) {
		try {
			return axios(`/data/api/graph/facility/${facilityId}`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getGraphScopes() {
		try {
			return axios('/data/api/graph/scopes', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updateGraph(graph) {
		try {
			return axios('/data/api/graph/', {
				method: 'put',

				data: graph
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	saveGraphState(definition, params) {
		try {
			return axios('/api/graph/stored', {
				method: 'post',
				params,
				data: definition
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getSavedGraphs(type) {
		try {
			return axios('/api/graph/stored', {
				method: 'get',
				params: { type }
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	loadGraphState(graphUuid) {
		try {
			return axios(`/api/graph/stored/${graphUuid}`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	deleteSavedGraph(graphUuid) {
		try {
			return axios(`/api/graph/stored/${graphUuid}`, {
				method: 'delete'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getFacilitySensorTree(facilityId) {
		try {
			return axios(`/api/facility/${facilityId}/sensortree`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getFacilityZoneTree(facilityId) {
		try {
			return axios(`/api/facility/${facilityId}/zonetree`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getCustomerGraphs() {
		try {
			return axios('/data/api/graph/customer', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getAllCustomerGraphs() {
		try {
			return axios('/data/api/graph/list', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getAllCustomerMergeableSensors() {
		try {
			return axios('/api/customer/mergeablesensorlists', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getAllCustomerSensors() {
		try {
			return axios('/api/customer/sensors?all=true', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getSensorImportInfo(sensoruuid) {
		try {
			return axios(`api/sensor/importinfo/${sensoruuid}`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getCustomerSensors(active) {
		let showactive = true;
		if (active) {
			showactive = active;
		}

		try {
			return axios(`/api/customer/sensors?active=${showactive}`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getCustomerIntegrations() {
		try {
			return axios('/api/integration/ttnapp/list', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updateProfile(profile) {
		try {
			return axios('/api/user', {
				method: 'put',
				data: profile
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	createFloorplan(floorplan) {
		const formData = new FormData();
		formData.append('image', floorplan.image[0]);
		formData.append('data', JSON.stringify(floorplan));
		try {
			return axios.post('/api/floorplan', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getFloorplans() {
		try {
			return axios('/api/floorplan', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updateFloorplan(floorplan) {
		try {
			return axios('/api/floorplan', {
				method: 'put',

				data: floorplan
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	deleteFloorplan(uuid) {
		try {
			return axios(`/api/floorplan/${uuid}`, {
				method: 'delete'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	createObservation(observation) {
		const formData = new FormData();
		formData.append('data', JSON.stringify(observation.data));
		observation.files.forEach(file => formData.append('files', file));

		try {
			return axios('/api/observation/', {
				data: formData,
				headers: { 'Content-Type': 'multipart/form-data' },
				method: 'post'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getObservation(uuid) {
		try {
			return axios(`/api/observation/${uuid}`, {
				method: 'GET'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updateObservation(uuid, observation) {
		const formData = new FormData();
		formData.append('data', JSON.stringify(observation.data));
		observation.files?.forEach(file => formData.append('files', file));

		try {
			return axios(`/api/observation/${uuid}`, {
				data: formData,
				headers: { 'Content-Type': 'multipart/form-data' },
				method: 'put'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	deleteObservation(uuid) {
		try {
			return axios(`/api/observation/${uuid}`, {
				method: 'delete'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getObservations(sensor, reading, range) {
		try {
			return axios(`/api/observation/${sensor}/${reading || 'all'}`, {
				method: 'get',
				params: range
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getScopedObservations(scope, range, page, pageSize, type) {
		LOG.debug(
			`getScopedObservations(): scope = ${d4l(scope)},  range = ${d4l(
				range
			)},  page = ${d4l(page)},  pageSize = ${d4l(pageSize)}`
		);

		try {
			// This could match /api/observation/scope/all as well
			return axios(`/api/observation/scope/${scope}`, {
				method: 'get',
				params: {
					page,
					range,
					type,
					pageSize
				}
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getTypedObservations(type, range) {
		try {
			return axios(`/api/observation/list/type/${type}`, {
				method: 'get',
				params: range
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updateObservationImage(uuid, image) {
		const formData = new FormData();
		formData.append('image', image);
		try {
			return axios.post(`/api/observation/${uuid}/image`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	domainCheck(domain) {
		try {
			return axios('/api/account/domaincheck', {
				method: 'post',
				data: domain
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	validateEmail(email) {
		try {
			return axios('/api/account/emailcheck', {
				method: 'post',
				data: { email }
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getReferredBy(email) {
		try {
			return axios('/api/account/referredby', {
				method: 'post',
				data: { email }
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	requestPasswordReset(email) {
		const data = { email };
		try {
			return axios('/api/account/password/resetrequest', {
				method: 'post',
				data
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	inviteUsers(emails) {
		const data = { emails };
		try {
			return axios('/api/user/invite/send', {
				method: 'post',
				data
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getInvite(token) {
		try {
			return axios(`/api/account/invite/${token}`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getCustomerInvites() {
		try {
			return axios('/api/customer/invite/all', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	acceptInvite(user, token) {
		const data = {
			user,
			token
		};
		try {
			return axios('/api/account/acceptinvite', {
				method: 'post',
				data
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	resetPassword(password, token) {
		const data = { password, token };
		try {
			return axios('/api/account/password/reset', {
				method: 'post',
				data
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	createRegistration(registration) {
		try {
			return axios('/api/account/registration', {
				method: 'post',
				data: registration
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updateRegistration(registration, uuid) {
		try {
			return axios(`/api/account/registration/${uuid}`, {
				method: 'put',
				data: registration
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	convertRegistration(registration, uuid) {
		try {
			return axios(`/api/account/registration/convert/${uuid}`, {
				method: 'post',
				data: registration
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	createAccount(customer, user) {
		try {
			return axios('/api/account', {
				method: 'post',
				data: { customer, user }
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	createOrder(order) {
		try {
			return axios('/api/order', {
				method: 'post',

				data: order
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	saveSurveyData(survey) {
		try {
			return axios('/api/survey', {
				method: 'post',

				data: survey
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	insertImport(importData) {
		try {
			return axios('/api/v2/readings/import', {
				method: 'post',
				data: importData
			});
		} catch (err) {
			return err;
		}
	},

	uploadImportFile(importfile, fileDetails, progressCallback) {
		const config = {
			onUploadProgress: progressCallback
		};

		const formData = new FormData();
		formData.append('importfile', importfile);
		formData.append('details', JSON.stringify(fileDetails));

		try {
			return axios.post('/data/api/import/upload', formData, config, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	startImport(importuuid, mode) {
		try {
			return axios(`/data/api/import/${importuuid}/start/${mode}`, {
				method: 'post'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getImportStatus(importuuid) {
		try {
			return axios(`/data/api/import/${importuuid}/status`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getImportResult(importGroupUuid) {
		try {
			return axios(`/data/api/import/${importGroupUuid}/result`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	listImports() {
		try {
			return axios('/data/api/import/list', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updateImport(uuid, importdata) {
		try {
			return axios(`/data/api/import/${uuid}`, {
				method: 'put',

				data: importdata
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getImport(uuid) {
		try {
			return axios(`/data/api/import/${uuid}`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	undoImport(uuid) {
		try {
			return axios(`/data/api/import/${uuid}`, {
				method: 'delete'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	cancelImport(uuid) {
		try {
			return axios(`/data/api/import/incomplete/${uuid}`, {
				method: 'delete'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	importSampleData(loadoptions) {
		try {
			return axios('/data/api/import/loadsampledata', {
				method: 'post',

				data: loadoptions
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getImportUploadUrl(uploadUuid) {
		try {
			return axios(`/api/v2/readings/import/${uploadUuid}/upload-url`, {
				method: 'post'
			});
		} catch (err) {
			return err;
		}
	},

	startImportAsync(groupUuid) {
		try {
			return axios(`/api/v2/readings/import/${groupUuid}/start`, {
				method: 'post'
			});
		} catch (err) {
			return err;
		}
	},

	putImportFileS3(uploadUrl, file, headers = {}) {
		try {
			return axios.put(uploadUrl, file, {
				headers: {
					...headers
				}
			});
		} catch (err) {
			return err;
		}
	},

	startV2Import(importuuid, mode) {
		try {
			return axios(`/data/api/v2/import/${importuuid}/start`, {
				method: 'post'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	tagEntity(tag) {
		try {
			return axios('/api/tag', {
				method: 'post',

				data: tag
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	search(term) {
		try {
			return axios(`/api/search?term=${term}`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	checkDomain(domain) {
		try {
			return axios('/api/account/domaincheck', {
				method: 'post',
				data: { domain }
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getSubscription() {
		try {
			return axios('/api/subscription/all', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updateCheckoutSession(checkoutsessionid) {
		try {
			return axios(`/api/subscription/stripe/${checkoutsessionid}`, {
				method: 'post'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	createCheckoutSession(params) {
		try {
			return axios('api/subscription/stripe/new', {
				method: 'post',
				data: params
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	downgradeSubscription() {
		try {
			return axios('/api/subscription/downgrade', {
				method: 'post'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getScore(sensor, params) {
		try {
			return axios(`/data/api/score/${sensor}`, {
				method: 'get',
				params
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updateUserAvatar(av) {
		const formData = new FormData();
		formData.append('image', av[0]);
		formData.append('data', JSON.stringify(av));
		try {
			return axios.post('/api/user/avatar', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	sendReferralEmail(sendto) {
		try {
			return axios('/api/referral/send', {
				method: 'post',

				data: { email: sendto }
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	listReferrals() {
		try {
			return axios('/api/referral/list', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updateOrgSquareLogo(av) {
		const formData = new FormData();
		formData.append('image', av[0]);
		formData.append('data', JSON.stringify(av));
		try {
			return axios.post('/api/customer/squarelogo', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updateOrgFullLogo(av) {
		const formData = new FormData();
		formData.append('image', av[0]);
		formData.append('data', JSON.stringify(av));
		try {
			return axios.post('/api/customer/fulllogo', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	listUserCredits() {
		try {
			return axios('/api/credit/user', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	listCustomerCredits() {
		try {
			return axios('/api/credit/customer', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	listLevelProfiles() {
		try {
			return axios('/api/level', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	createLevelProfile(levelProfile) {
		try {
			return axios('/api/level', {
				method: 'post',

				data: levelProfile
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updateLevelProfile(seriesuuid, levelProfile) {
		try {
			return axios(`/api/level/${seriesuuid}`, {
				method: 'put',

				data: levelProfile
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getLevelProfile(seriesuuid) {
		try {
			return axios(`/api/level/${seriesuuid}`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	deleteLevelProfile(seriesuuid) {
		try {
			return axios(`/api/level/${seriesuuid}`, {
				method: 'delete'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getLevelProfileForScope(scope, scopeid) {
		try {
			return axios(`/api/level/scoped/${scope}/${scopeid}`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getLevelDescription(seriesuuid) {
		try {
			return axios(`/api/level/description/${seriesuuid}`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updateLevelDescription(seriesuuid, levelDesc) {
		try {
			return axios(`/api/level/description/${seriesuuid}`, {
				method: 'put',

				data: levelDesc
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	createFavorite(type, typeid) {
		try {
			return axios('/api/favorite', {
				method: 'post',

				data: {
					type,
					typeid
				}
			});
		} catch (err) {}
	},

	deleteFavorite(uuid) {
		try {
			return axios(`/api/favorite/${uuid}`, {
				method: 'delete'
			});
		} catch (err) {}
	},

	getFavorites(filter) {
		try {
			return axios(`/api/favorite/${filter}`, {
				method: 'get'
			});
		} catch (err) {}
	},

	updateSpaceImage(space, image) {
		const formData = new FormData();
		formData.append('image', image[0]);
		try {
			return axios.post(`/api/zone/${space.uuid}/image`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updateLocationImage(location, image) {
		const formData = new FormData();
		formData.append('image', image[0]);
		try {
			return axios.post(`/api/facility/${location.id}/image`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getSpaceDetails(uuid) {
		try {
			return axios(`/api/zone/${uuid}/details`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getSpaceTypes() {
		try {
			return axios('/api/zone/types', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	createTask(task) {
		try {
			return axios('/api/task', {
				method: 'post',

				data: task
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getTasks() {
		try {
			return axios('/api/task/customer', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updateTask(uuid, task) {
		try {
			return axios(`/api/task/${uuid}`, {
				method: 'put',

				data: task
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	deleteTask(uuid) {
		try {
			return axios(`/api/task/${uuid}`, {
				method: 'delete'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	listComments(type, typeid) {
		try {
			return axios(`/api/comment/type/${type}/${typeid}`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	listCommentsByUser(uuid) {
		try {
			return axios(`/api/comment/user/${uuid}`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updateComment(comment, uuid) {
		try {
			return axios(`/api/comment/${uuid}`, {
				method: 'put',
				data: comment
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	createComment(formData, type, typeId) {
		try {
			return axios.post(`/api/comment/type/${type}/${typeId}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
		} catch (error) {
			Sentry.captureException(error);
			return error;
		}
	},

	deleteComment(uuid) {
		try {
			return axios(`/api/comment/${uuid}`, {
				method: 'delete'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getFacilityWeather(facilityId, range, unit) {
		try {
			return axios(`/data/api/weather/facility/${facilityId}/history`, {
				method: 'get',
				params: {
					start: range.start,
					end: range.end,
					unit: unit || 'f'
				}
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	listManufacturers() {
		try {
			return axios('/api/sensor/manufacturers', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	listEventProfiles() {
		try {
			return axios('/api/event/profiles', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	createEventProfile(profile) {
		try {
			return axios('/api/event/profile', {
				method: 'post',
				data: profile
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getEventDetail(uuid) {
		try {
			return axios(`/api/event/detail/${uuid}`, {
				method: 'GET'
			});
		} catch (error) {
			Sentry.captureException(error);
			return error;
		}
	},

	updateEventDetail(uuid, data) {
		try {
			return axios(`/api/event/detail/${uuid}`, {
				method: 'PUT',
				data
			});
		} catch (error) {
			Sentry.captureException(error);
			return error;
		}
	},

	updateEventProfile(profileUuid, profile) {
		try {
			return axios(`/api/event/profile/${profileUuid}`, {
				method: 'put',
				data: profile
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	toggleEvent(eventProfileUuid, status) {
		try {
			return axios(`/api/event/profile/track/${eventProfileUuid}`, {
				method: 'put',
				data: { status }
			});
		} catch (error) {
			Sentry.captureException(error);
			return error;
		}
	},

	deleteEventProfile(profileUuid) {
		try {
			return axios(`/api/event/profile/${profileUuid}`, {
				method: 'delete'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getEventProfilesHasActiveEvent(profileUuid) {
		try {
			return axios(`/api/event/profile/hasactiveevent/${profileUuid}`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getEventProfilesHasEvent(profileUuid) {
		try {
			return axios(`/api/event/profile/hasevent/${profileUuid}`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	listEventSubscriptions() {
		try {
			return axios('/api/event/subscriptions', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	createEventSubscription(profileUuid, frequency) {
		try {
			return axios(`/api/event/subscription/${profileUuid}`, {
				method: 'post',
				data: { frequency }
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	deleteEventSubscription(profileUuid) {
		try {
			return axios(`/api/event/subscription/${profileUuid}`, {
				method: 'delete'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	createTrapLocation(traplocation) {
		const formData = new FormData();
		formData.append('data', JSON.stringify(traplocation.data));
		traplocation.files.forEach(file => formData.append('files', file));

		try {
			return axios.post('/api/traplocation/', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getTrapLocationByUuid(uuid) {
		try {
			return axios(`/api/traplocation/${uuid}`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updateTrapLocation(traplocation) {
		const formData = new FormData();
		formData.append('data', JSON.stringify(traplocation.data));
		traplocation.files.forEach(file => formData.append('files', file));

		try {
			return axios.put(
				`/api/traplocation/${traplocation.data.uuid}`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}
			);
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	listTraplocations() {
		try {
			return axios('/api/traplocation/', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getTrapLocationHistory(trapLocationUuid) {
		try {
			return axios(`/api/traplocation/${trapLocationUuid}/history`, {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	deleteTrapLocation(trapLocationUuid) {
		try {
			return axios(`/api/traplocation/${trapLocationUuid}`, {
				method: 'delete'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	createTrapLocationReplace(trapLocationUuid, new_type, replaced_at, note) {
		try {
			return axios(`/api/traplocation/${trapLocationUuid}/replace`, {
				method: 'post',
				data: { new_type, replaced_at, note }
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	// getTrapLocationHistory(trapLocationUuid) {
	//     try {
	//         return axios('/')
	//     }
	// },

	getPestList() {
		try {
			return axios('/api/pest/list', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getCustomerPestList() {
		try {
			return axios('/api/pest/customerlist', {
				method: 'get'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	addToCustomerPestList(pestUuid) {
		try {
			return axios(`/api/pest/customerlist/${pestUuid}`, {
				method: 'put'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	removeFromCustomerPestList(pestUuid) {
		try {
			return axios(`/api/pest/customerlist/${pestUuid}`, {
				method: 'delete'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	addPestImage(pestUuid, image, lifecycleUuid) {
		const formData = new FormData();
		formData.append('image', image);
		formData.append('pestuuid', pestUuid);
		formData.append('lifecycleuuid', lifecycleUuid);
		try {
			return axios.post('/api/pest/image', formData, {
				'Content-Type': 'multipart/form-data'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updatePestImage(imageUuid, image, lifecycleUuid) {
		const formData = new FormData();
		formData.append('image', image);
		formData.append('lifecycleuuid', lifecycleUuid);
		try {
			return axios.put(`/api/pest/image/${imageUuid}`, formData, {
				'Content-Type': 'multipart/form-data'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	deletePestImage(imageUuid) {
		try {
			return axios(`/api/pest/image/${imageUuid}`, {
				method: 'delete'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	getPestLifecycles() {
		try {
			return axios('/api/pest/lifecycles', {});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	addPestIdentification(observationUuid, pestUuid, count) {
		try {
			return axios('/api/observation/pest', {
				method: 'post',
				data: {
					observation: observationUuid,
					pest: pestUuid,
					count
				}
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	addPestIdentifications(observationUuid, identifications) {
		try {
			return axios('/api/observation/pest', {
				method: 'post',
				data: {
					observation: observationUuid,
					identifications
				}
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	removePestIdentification(observationUuid, pestUuid) {
		try {
			return axios(`/api/observation/pest/${observationUuid}/${pestUuid}`, {
				method: 'delete'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	createPest(pest) {
		try {
			return axios('/api/pest/create', {
				method: 'post',
				data: pest
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	updatePest(pest) {
		try {
			return axios(`/api/pest/${pest.uuid}`, {
				method: 'put',
				data: pest
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	deletePest(pest) {
		try {
			return axios(`/api/pest/${pest.uuid}`, {
				method: 'delete'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	loadPoiBySpace(params) {
		try {
			return axios(
				`/api/poi?calculate_by_time=false&start=${params.start}&end=${params.end}`,
				{
					method: 'get'
				}
			);
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	loadPoiByTime(params) {
		try {
			return axios(
				`/api/poi?calculate_by_time=true&start=${params.start}&end=${params.end}`,
				{
					method: 'get'
				}
			);
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	loadPestDistribution(params) {
		try {
			return axios(
				`/api/pest/distribution?start=${params.start}&end=${params.end}`,
				{
					method: 'get'
				}
			);
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	loadPestCountsBySpace(params) {
		try {
			return axios(
				`/api/pest/space/distribution?start=${params.start}&end=${params.end}`,
				{
					method: 'get'
				}
			);
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	loadPestCountsByTime(params) {
		try {
			return axios(
				`/api/pest/space/distribution?get_by_time=true&start=${params.start}&end=${params.end}`,
				{
					method: 'get'
				}
			);
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	loadIpmExportReport(params) {
		try {
			return axios(
				`/api/ipm/export-report?start=${params.start}&end=${params.end}`,
				{
					method: 'get'
				}
			);
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	loadASHRAEClassification() {
		try {
			return axios.get(`/api/properties/listall?scope=ashrae`, {
				method: 'GET'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	loadRoles() {
		try {
			return axios('/api/jobtitle/list', {
				method: 'GET'
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	removeImage(uuid) {
		try {
			return axios(`/api/image/${uuid}`, {
				method: 'DELETE'
			});
		} catch (error) {
			Sentry.captureException(error);
			return error;
		}
	},

	removeProperty(id) {
		try {
			return axios(`/api/properties/scope_properties/${id}`, {
				method: 'DELETE'
			});
		} catch (error) {
			Sentry.captureException(error);
			return error;
		}
	},

	getEventList(params) {
		try {
			return axios('/api/event/list', {
				params,
				method: 'GET'
			});
		} catch (error) {
			Sentry.captureException(error);
			return error;
		}
	},

	getNotificationText(params) {
		try {
			return axios(`/api/event/profile/getpopupmessage`, {
				params,
				method: 'GET'
			});
		} catch (error) {
			Sentry.captureException(error);
			return error;
		}
	},

	updateSubscribers(params) {
		try {
			return axios(`/api/event/profile/subscribe/edit/${params.uuid}`, {
				data: params,
				method: 'POST'
			});
		} catch (error) {
			Sentry.captureException(error);
			return error;
		}
	},

	deprovisionSensors({ sensors, customerId }) {
		const data = { sensors, customerId };
		try {
			return axios('api/v2/provisioning/sensors', {
				method: 'delete',
				data
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	provisionSensors({ sensors, customerId, region }) {
		const data = { sensors, customerId, region };
		try {
			return axios('api/v2/provisioning/sensors', {
				method: 'post',
				data
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	deprovisionGateways({ gateways, customerId }) {
		const data = { gateways, customerId };
		try {
			return axios('api/v2/provisioning/gateways', {
				method: 'delete',
				data
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	provisionGateways({ gatewaysSerial, region, customerId }) {
		const data = { gatewaysSerial, region, customerId };
		try {
			return axios('api/v2/provisioning/gateways', {
				method: 'post',
				data
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	provisionOrder({ shipbobOrderId, region, customerId }) {
		const data = { shipbobOrderId, region, customerId };
		try {
			return axios('api/v2/provisioning/order', {
				method: 'post',
				data
			});
		} catch (err) {
			Sentry.captureException(err);
			return err;
		}
	},

	generateExportDownloadUrl(filepath) {
		try {
			return axios.get(`/api/v2/exports/${filepath}`);
		} catch (err) {
			return err;
		}
	},

	exportSensorData(data) {
		try {
			return axios('/api/v2/exports', {
				method: 'post',
				data
			});
		} catch (err) {
			return err;
		}
	}
};

export default Api;
